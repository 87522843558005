import React from 'react'
import axios from 'axios'

const AxiosContext = React.createContext(null)

const AxiosProvider = ({ children, baseUrl, token }) => {

  const memoInstance = React.useMemo(() => {
    return axios.create({
      method: 'POST',
      baseURL: baseUrl,
      timeout: 5000,
      headers: {
        authorization: `Bearer ${token}`,
        'Content-Type': 'application/graphql',
      },
    })
  }, [baseUrl, token])

  return (
    <AxiosContext.Provider value={memoInstance}>
      {children}
    </AxiosContext.Provider>
  )
}

const useAxios = () => {
  const context = React.useContext(AxiosContext)

  if (context === undefined) {
    throw new Error('useCount must be used within a CountProvider')
  }
  return context
}

export { AxiosProvider, useAxios }
