import React from 'react'
import { node } from 'prop-types'
import 'what-input'

import { UserbaseUserProvider } from './services/userbase/userProvider'
import { UserbaseDeckDBProvider } from './services/userbase/deckDBProvider'

export const wrapRootElement = ({ element }) => {
  return (
    <UserbaseUserProvider>
      <UserbaseDeckDBProvider>{element}</UserbaseDeckDBProvider>
    </UserbaseUserProvider>
  )
}

wrapRootElement.propTypes = {
  element: node,
}
