import { gql } from 'graphql-request'
export const DECK_DB = 'folksy-decks'

export const DECK_INFO_QUERY = gql`
  query People {
    users(group: "staff") {
      id
    }
  }
`
