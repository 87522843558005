import userbase from 'userbase-js'

import { DECK_DB } from '../../domains/decks'
import { useUserbaseUserState, useUserbaseUserDispatch } from './userProvider'
import {
  useUserbaseDeckDBState,
  useUserbaseDeckDBDispatch,
} from './deckDBProvider'

export const useUserbaseUser = () => {
  const { user } = useUserbaseUserState()
  const userbaseUserDispatch = useUserbaseUserDispatch()
  const userbaseDeckDBDispatch = useUserbaseDeckDBDispatch()

  const signUp = async (username, password, email, rememberMe) => {
    try {
      const userbaseUser = await userbase.signUp({
        username,
        password,
        email,
        rememberMe: rememberMe ? 'local' : 'session',
      })

      userbaseUserDispatch({ type: 'setUser', payload: userbaseUser })
      return { status: 200 }
    } catch (error) {
      return error
    }
  }

  const signIn = async (username, password, rememberMe) => {
    try {
      const userbaseUser = await userbase.signIn({
        username,
        password,
        rememberMe: rememberMe ? 'local' : 'session',
      })

      userbaseUserDispatch({ type: 'setUser', payload: userbaseUser })
      return { status: 200 }
    } catch (error) {
      return error
    }
  }

  const signOut = () => {
    userbase
      .signOut()
      .then(() => {
        // @TODO clear decks too
        userbaseUserDispatch({ type: 'logout' })
        userbaseDeckDBDispatch({ type: 'clear' })
      })
      .catch((err) => console.error(err))
  }

  const setUserDeck = (deckId) => {
    userbase
      .updateUser({
        profile: { deckId },
      })
      .then(() => {
        userbaseUserDispatch({
          type: 'setUser',
          payload: {
            ...user,
            profile: {
              ...user.profile,
              deckId,
            },
          },
        })
      })
      .catch((e) => console.error(e))
  }

  const forgotPassword = async (username) => {
    try {
      await userbase.forgotPassword({
        username,
      })

      return { status: 200 }
    } catch (error) {
      return error
    }
  }

  const changePassword = async ({ currentPassword, newPassword }) => {
    try {
      await userbase.updateUser({
        currentPassword,
        newPassword,
      })

      return { status: 200 }
    } catch (error) {
      return error
    }
  }

  return {
    signUp,
    signIn,
    signOut,
    setUserDeck,
    forgotPassword,
    changePassword,
  }
}

export const useUserbaseDeckDB = () => {
  const { deck } = useUserbaseDeckDBState()

  const addDeck = async ({
    name,
    uri,
    token,
    knownIds = [],
    cardCount = 0,
  }) => {
    const deckName = name || `default-${uri}`

    try {
      const insertRes = await userbase.insertItem({
        databaseName: DECK_DB,
        item: { name: deckName, uri, token, knownIds, cardCount },
      })

      return insertRes
    } catch (error) {
      console.error(error)
    }
  }

  const updateDeckKnownIds = async (ids) => {
    try {
      const updateDeckKnownIdsRes = await userbase.updateItem({
        databaseName: DECK_DB,
        item: { ...deck.item, knownIds: ids },
        itemId: deck.itemId,
      })

      return updateDeckKnownIdsRes
    } catch (error) {
      console.error(error)
    }
  }

  const deleteDeck = (id) => {
    userbase
      .deleteItem({
        databaseName: DECK_DB,
        itemId: id,
      })
      .then(() => {
        // @TODO show global banner
      })
      .catch((e) => console.error(e))
  }

  const updateCardCount = (count) => {
    userbase
      .updateItem({
        databaseName: DECK_DB,
        item: { ...deck.item, cardCount: count },
        itemId: deck.itemId,
      })
      .then(() => {
        // @TODO anything?
      })
      .catch((e) => console.error(e))
  }

  const updateDeckName = (name, success = () => null) => {
    if (deck.item.name === name) {
      success()
      return
    }

    userbase
      .updateItem({
        databaseName: DECK_DB,
        item: { ...deck.item, name },
        itemId: deck.itemId,
      })
      .then(() => {
        // @TODO anything?
        success()
      })
      .catch((e) => console.error(e))
  }

  return {
    addDeck,
    updateDeckKnownIds,
    deleteDeck,
    updateCardCount,
    updateDeckName,
  }
}
