/** @typedef { import('./types').UserProviderState } UserProviderState */
/** @typedef { import('./types').UserProviderAction } UserProviderAction */
/** @typedef { import('./types').UserProviderDispatch } UserProviderDispatch */

import React from 'react'
import userbase from 'userbase-js'
import smoothscroll from 'smoothscroll-polyfill'

/** @type {React.Context<UserProviderState|undefined>} */
export const UserbaseUserStateContext = React.createContext(undefined)
/** @type {React.Context<UserProviderDispatch|undefined>} */
export const UserbaseUserDispatchContext = React.createContext(undefined)

/** @type {UserProviderState} */
const initialState = {
  user: null,
  ready: false,
}

/**
 * @param {UserProviderState} state
 * @param {UserProviderAction} action
 *
 * @returns {UserProviderState}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'setUser':
      return {
        ...state,
        user: action.payload,
      }
    case 'ready':
      return { ...state, ready: true }
    case 'logout':
      return { ...initialState, ready: true }
    default:
      throw new Error()
  }
}

/**
 * @typedef UserbaseUserProviderProps
 * @prop {React.ReactNode} children
 */

/**
 * @param {UserbaseUserProviderProps} props
 */
export const UserbaseUserProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    smoothscroll.polyfill()
  }, [])

  React.useEffect(() => {
    userbase
      .init({
        appId: `${process.env.GATSBY_USERBASE_APP_ID}`,
      })
      .then((session) => {
        if (session.user) {
          dispatch({ type: 'setUser', payload: session.user })
        }

        dispatch({ type: 'ready' })
      })
      .catch((e) => console.error(e))
  }, [dispatch])

  return (
    <UserbaseUserStateContext.Provider value={state}>
      <UserbaseUserDispatchContext.Provider value={dispatch}>
        {children}
      </UserbaseUserDispatchContext.Provider>
    </UserbaseUserStateContext.Provider>
  )
}

export const useUserbaseUserState = () => {
  const context = React.useContext(UserbaseUserStateContext)
  if (context === undefined) {
    throw new Error(
      'useUserbaseUserState must be used within a UserbaseUserProvider'
    )
  }
  return context
}

export const useUserbaseUserDispatch = () => {
  const context = React.useContext(UserbaseUserDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useUserbaseUserDispatch must be used within a UserbaseUserProvider'
    )
  }
  return context
}
