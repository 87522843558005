/** @typedef { import('./types').DeckDBProviderState } DeckDBProviderState */
/** @typedef { import('./types').DeckDBProviderDispatch } DeckDBProviderDispatch */
/** @typedef { import('./types').DeckDBProviderAction } DeckDBProviderAction */
import React from 'react'
import userbase from 'userbase-js'

import { useUserbaseDeckDB } from './'
import { useUserbaseUserState } from './userProvider'
import { AxiosProvider, useAxios } from '../api'

import { DECK_DB, DECK_INFO_QUERY } from '../../domains/decks'

import 'what-input'

/** @type {React.Context<DeckDBProviderState|undefined>} */
export const UserbaseDeckDBStateContext = React.createContext(undefined)
/** @type {React.Context<DeckDBProviderDispatch|undefined>} */
export const UserbaseDeckDBDispatchContext = React.createContext(undefined)

/**
 * @TODO (Phase II)
 *
 * - Switch to React Query so that we don't need the apollo client provider
 *   thing and instead we can get stuff on demand with a hook...?
 * - remove (from userbase) known ids no longer in the data
 * - use active deck data in whatever way (useQuery here)
 *
 * something like:
 *
 * const validKnownIds = deck
 *   ? deck.item.knownIds.filter((knownId) => activeIds.includes(knownId))
 *   : []
 *
 * and then user the userbase service to save the knownIds again
 */
const DeckValidation = ({ children }) => {
  const { deck } = useUserbaseDeckDBState()
  const [validated, setValidated] = React.useState(false)
  const { updateCardCount } = useUserbaseDeckDB()

  let activeCount = null

  const instance = useAxios()
  const [data, setData] = React.useState(null)

  React.useEffect(() => {
    async function query() {
      try {
        const {
          data: { data },
        } = await instance.request({
          data: DECK_INFO_QUERY,
        })

        setData(data)
      } catch (error) {
        console.error(error)
      }
    }

    query()
  }, [instance])

  if (data && !validated) {
    const userCount = data.users.length
    activeCount = userCount === deck.item.cardCount ? null : userCount
  }

  React.useEffect(() => {
    if (!activeCount) {
      return
    }

    setValidated(true)
    updateCardCount(activeCount)
  }, [activeCount, updateCardCount])

  React.useEffect(() => {
    setValidated(false)
  }, [deck.itemId])

  return children
}

const getDeck = (user, decks) => {
  if (!user || decks.length === 0) {
    return null
  }

  if (user.profile && user.profile.deckId) {
    return decks.find((d) => d.itemId === user.profile.deckId) || decks[0]
  }

  return decks[0]
}

/** @type {DeckDBProviderState} */
const initialState = {
  decks: [],
  connected: false,
}

/**
 * @param {DeckDBProviderState} state
 * @param {DeckDBProviderAction} action
 *
 * @returns {DeckDBProviderState}
 */
const reducer = (state, action) => {
  switch (action.type) {
    case 'setDecks':
      return {
        ...state,
        decks: action.payload,
        // newDeck: getNewDeck(action.payload, state.decks),
      }
    case 'connect':
      return { ...state, connected: true }
    case 'disconnect':
      return { ...state, connected: false }
    case 'clear':
      return { ...initialState }
    default:
      throw new Error()
  }
}

/**
 * @typedef UserbaseDeckDBProviderProps
 * @prop {React.ReactNode} children
 */

/**
 * @param {UserbaseDeckDBProviderProps} props
 */
export const UserbaseDeckDBProvider = ({ children }) => {
  const { ready, user } = useUserbaseUserState()
  const [state, dispatch] = React.useReducer(reducer, initialState)

  const { decks, connected } = state
  const deck = getDeck(user, decks)

  const stateWithCurrentDeck = {
    ...state,
    deck,
  }

  const clientConfig = deck
    ? { uri: deck.item.uri, token: deck.item.token }
    : { uri: null, token: null }

  // const memoApolloClient = React.useMemo(() => {
  //   return getApolloClient(clientConfig.uri, clientConfig.token)
  // }, [clientConfig.uri, clientConfig.token])

  React.useEffect(() => {
    if (!ready || !user) {
      return
    }

    if (connected) {
      return
    }

    userbase
      .openDatabase({
        databaseName: DECK_DB,
        changeHandler: function (items) {
          dispatch({ type: 'setDecks', payload: items })
        },
      })
      .then(() => {
        dispatch({ type: 'connect' })
      })
      .catch((e) => console.error(e))
  }, [ready, user, dispatch, connected])

  if (clientConfig.uri && clientConfig.token) {
    return (
      <AxiosProvider baseUrl={clientConfig.uri} token={clientConfig.token}>
        {/* @TODO add the react query stuff and pass the instance to the hooks */}
        <UserbaseDeckDBStateContext.Provider value={stateWithCurrentDeck}>
          <UserbaseDeckDBDispatchContext.Provider value={dispatch}>
            <DeckValidation>{children}</DeckValidation>
          </UserbaseDeckDBDispatchContext.Provider>
        </UserbaseDeckDBStateContext.Provider>
      </AxiosProvider>
    )
  } else {
    return (
      <UserbaseDeckDBStateContext.Provider value={stateWithCurrentDeck}>
        <UserbaseDeckDBDispatchContext.Provider value={dispatch}>
          {children}
        </UserbaseDeckDBDispatchContext.Provider>
      </UserbaseDeckDBStateContext.Provider>
    )
  }
}

export const useUserbaseDeckDBState = () => {
  const context = React.useContext(UserbaseDeckDBStateContext)
  if (context === undefined) {
    throw new Error(
      'useUserbaseDeckDBState must be used within a UserbaseDeckDBProvider'
    )
  }
  return context
}

export const useUserbaseDeckDBDispatch = () => {
  const context = React.useContext(UserbaseDeckDBDispatchContext)
  if (context === undefined) {
    throw new Error(
      'useUserbaseDeckDBDispatch must be used within a UserbaseDeckDBProvider'
    )
  }
  return context
}
